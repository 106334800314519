<template>
   
    <b-card-actions action-collapse title="Close Deal Info Section">  
        <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                    <b-form-group>
                    <label>Enquiry</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="name"
                    >
                          <b-form-input
                                v-model="input.code"
                                placeholder="Enquiry Number"
                               readonly
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                 <b-col md="6">
                    <b-form-group>
                    <label>Action</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="uri"
                    >  <v-select
                            v-model="input.action"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                        
                            :options="option_looking"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                 <b-col md="6">
                    <b-form-group>
                    <label>Price </label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="price"
                    >
                          <b-form-input
                                v-model="input.price"
                                placeholder="Price"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group>
                    <label>Date</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="price"
                    >
                       <b-form-datepicker
                    id="date"
                    v-model="input.date"
                     today-button
                        reset-button
                        close-button
                    class="mb-1"
                  
                    />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group>
                    <label>Commission</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="commission"
                    >
                          <b-form-input
                                v-model="input.commission"
                                placeholder="Commission"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group>
                    <label>Service Description</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="min:0"
                    name="service_description"
                    >
                          <b-form-input
                                v-model="input.service_description"
                                placeholder="Service Description"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group>
                    <label>Service Price</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="min:0"
                    name="service_price"
                    >
                          <b-form-input
                                v-model="input.service_price"
                                placeholder="Service Price"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>  

               <b-col md="12">
                    <b-form-group>
                    <label>Remark</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="min:0"
                    name="remark"
                    >
                          <b-form-input
                                v-model="input.remark"
                                placeholder="Remark"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                 <b-col md="12">
                      <h5>Client Information</h5>
                      <hr />
                </b-col>
                <b-col md="6">
                        <label>First Name</label>
                        <b-form-group>
                        {{ input.firstname }}
                        </b-form-group>
                </b-col>
                <b-col md="6">
                    <label>Last Name</label>
                    <b-form-group>
                        {{ input.lastname }}
                        </b-form-group>
                </b-col>
                <b-col md="6">
                        <label>Email</label>
                        <b-form-group>
                        {{ input.email }}
                        </b-form-group>
                </b-col>
                <b-col md="6">
                    <label>Phone</label>
                    <b-form-group>
                        {{ input.phone }}
                        </b-form-group>
                </b-col>
                  <b-col md="12">
                      <h5>Old Owner Information</h5>
                      <hr />
                </b-col>
                <b-col md="6">
                        <label>First Name</label>
                        <b-form-group>
                        {{ input.owner_firstname }}
                        </b-form-group>
                </b-col>
                <b-col md="6">
                        <label>Last Name</label>
                        <b-form-group>
                         {{ input.owner_lastname }}
                        </b-form-group>
                </b-col>
                <b-col md="6">
                        <label>Email</label>
                        <b-form-group>
                        {{ input.owner_email }}
                        </b-form-group>
                </b-col>
                <b-col md="6">
                    <label>Phone</label>
                    <b-form-group>
                        {{ input.owner_phone }}
                        </b-form-group>
                </b-col>
                 <b-col md="12">
                      <h5>For Rent</h5>
                      <hr />
                </b-col>
                <b-col md="6">
                        <label>Start Date</label>
                        <b-form-group>
                       <b-form-datepicker
                        id="start_date"
                        v-model="input.start_date"
                         today-button
      reset-button
      close-button
                        class="mb-1"
                      
                        />
                        </b-form-group>
                </b-col>
                <b-col md="6">
                        <label>Expire Date</label>
                        <b-form-group>
                         <b-form-datepicker
                        id="expire_date"
                        v-model="input.expire_date"
                         today-button
      reset-button
      close-button
                        class="mb-1"
                      
                        />
                        </b-form-group>
                </b-col>

                
               
                
              
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-col>
                
              </b-row>
              
            </b-form>
        </validation-observer>
         <b-table
                            striped
                            hover
                            responsive
                            :per-page="100"
                            :current-page="1"
                            :items="properties_selected"
                            :fields="fields_propertiesselected"
                            show-empty
                            empty-text="No matching records found"
                            
                            
                            
                        >
                      
                      
                        <template #cell(property)="data">
                            <div class="list-property">
                            <a to="{ name: 'property-create'}">{{ data.item.property_code }}</a>
                            <b-badge v-if="data.item.action_id == 1" variant="light-secondary">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 2" variant="light-info">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 3" variant="light-success">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 4" variant="light-warning">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.status_id == 1" variant="light-success">
                                {{ data.item.status_code }}
                            </b-badge>
                            <b-badge v-else variant="light-secondary">
                                {{ data.item.status_code }}
                            </b-badge>

                            <br />
                            {{ data.item.place_name }}<br /><br />
                            {{ data.item.comment }}
                            </div>
                            
                            
                        </template>
                        <template #cell(image)="data">
                                <b-button variant="flat-primary" class="pb0" :to="{ name: 'property-upload', params: { id: data.item.id } }">
                                <b-img   class="img_property" v-if="data.item.fullfilepath "
                                            :src="data.item.fullfilepath"
                                            fluid
                                        />
                                <b-img   class="img_property" v-else
                                            :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                                            fluid
                                        />
                                </b-button>
                                
                            </template>
                        
                            <template #cell(owner)="data">
                                
                                {{ data.item.owner_firstname }}
                                 {{ data.item.owner_lasttname }}
                        
                            </template>
                            <template #cell(price_rent)="data">
                                {{ data.item.price_rent | getcomma }}
                            
                            </template>
                            <template #cell(price_sale)="data">
                                {{ data.item.price_sale | getcomma }}
                                
                            </template>
                        </b-table>
                        
    </b-card-actions>
      

 
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup,BFormDatepicker,BTable,BPagination,BImg ,BBadge
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'

export default {
  components: {
    BCardActions,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormRating,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BTable,BPagination,
    BImg,
    BBadge

  },
  directives: {
    Ripple,
  },
  filters: {
    getcomma: function(text){
      if(!text){
        return ''

      }
			 var nStr = text;
			nStr += '';
			var x = nStr.split('.');
			var x1 = x[0];
			var x2 = x.length > 1 ? '.' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1 ;
		},
  },
  data() {
    return {
        id:router.currentRoute.params.id,
        property_id:router.currentRoute.params.property_id,
        userData: JSON.parse(localStorage.getItem('userData')),
        properties_selected:[],
        input:{
          enquiryId:router.currentRoute.params.id,
          propertyId:router.currentRoute.params.property_id,
          code: null,
          action:null,
  
          price:null,
          commission:null,
          owner_name:null,
          service_description:null,
          service_price:null,
          remark:null,
          date:null,
          start_date:null,
          expire_date:null,
         
        },
        option_looking: 
        [
          
            { id: "Sale", title: 'Sale' }, 
            { id: "Rent", title: 'Rent' }, 
          
         ],
        fields_propertiesselected:[
            {
                key: 'id', label: '',
            },
      
            {
                key: 'image', label: 'Image',
            },
            {
                key: 'property', label: 'Property Code',
            },
             {
                key: 'owner', label: 'Owner',
            },
            {
                key: 'units', label: 'units',
            },
            {
                key: 'floors', label: 'Floor',
            },
            {
                key: 'type_name', label: 'Type',
            },
            {
                key: 'buildiing', label: 'Building',
            },
            {
                key: 'bedroom', label: 'Bd',
            },
            {
                key: 'sqm', label: 'Sqm',
            },
            {
                key: 'price_rent', label: 'Rent',
            },
            {
                key: 'price_sale', label: 'Sale',
            },
            {
                key: 'exp', label: 'Exp/Avail Date  ',
            },
            {
                key: 'rating', label: 'Rating',
            },
            {
                key: 'furniture_name', label: 'Furniture',
            },
            {
                key: 'bts_name', label: 'BTS',
            },
            {
                key: 'mrt_name', label: 'MRT',
            },
            {
                key: 'zone_name', label: 'Zone',
            },
             {
                key: 'id', label: 'ID',
            },
            
            
        ],
        
     
    }
  },
  created() {
     
     if(this.id != undefined){
        
        this.getData();
        
       
      }
   
      
  },
  methods: {
   
    async getData(){
     
     // var place = await axios.get('blog-category/v1/detail/'+this.id);
      var enquiry = await axios.get('enquiry/v1/get/'+this.id);
      var property = await axios.get('property/v1/getdetail/'+this.property_id);
      var prop = property.data.data[0]
      console.log('prop',prop)
     if(enquiry){
         let enq  = enquiry.data.data;
         this.input.code = enq.code;
        // console.log('input',this.input);
         this.input.action= enq.looking;
         
         // this.input.price = 500;
       
         if(enq.looking == 'Sale'){
             this.input.price = prop.sale_price;
         }else{
              this.input.price = prop.rent_price;
         }
         console.log('input',prop);
            this.input.owner_firstname = prop.owner_firstname;
             this.input.owner_lastname = prop.owner_lastname;
            this.input.owner_email = prop.owner_email;
              this.input.owner_phone = prop.owner_phone;
              this.properties_selected.push(prop);

     }
     
    
      
    },

    
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            console.log('input',this.input);
            this.input.created_by = this.userData.id;
            var data = JSON.stringify(this.input);
            axios.post('close-deal/v1/add',data)
              .then(res => { 
                console.log('res',res);
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                
                  
                }else{
                  this.$router.push('/close-deal/list', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully created.`,
                        },
                      })
                  });
                  

                }
            // this.actions = res.data.data; 
            }).catch(error => {
                this.makeToast('danger','Error 403',error);
                console.log('error',error);
            })
          // eslint-disable-next-line
         
        }
      })
    },
    
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>